"use strict";

import {find, addClass, on, findAll} from "@elements/dom-utils"

export function init() {
    let scrollHint = findAll('.js-scroll-hint');
    if(scrollHint){
        scrollHint.map(e =>{
            e.addEventListener('click', function () {
                window.scrollTo({
                    top: window.innerHeight,
                    behavior: 'smooth'
                });
            });
            on("scroll", function(){
                addClass('is-hidden', e);
            }, window)
        })
    }
}
