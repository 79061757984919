"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope,cleanUpInScope,onFind} from "@elements/init-modules-in-scope";
import {findIn,removeClass, on, findAllIn} from '@elements/dom-utils';

export function init () {
    onFind('.js-render-template', function (renderTemplates) {
        let renderAfterLoaded = renderTemplates.getAttribute('data-render-template-after-loaded') || false;
        renderAfterLoaded = (renderAfterLoaded === "true");

        if(renderAfterLoaded) {
            on('load', function() {
                renderTemplate(renderTemplates);
            }, window);
        } else {
            onEnterViewPort(renderTemplates, function (renderItem) {
                renderTemplate(renderItem);
            }, { offset: 300 });
        }
    });
}

export function renderTemplate(renderItem) {
    let template = findIn('.js-render-template__template',renderItem);
    let parent = renderItem.parentNode;

    if(template) {
        renderItem.innerHTML = template.innerHTML;
        removeClass('js-render-template',renderItem);

        template.remove();
    }

    initInScope(renderItem);
}

export function renderAllTemplates() {
    let templates = findAllIn('.js-render-template', document.body);
    templates.map(template => {
        renderTemplate(template);
    });
}