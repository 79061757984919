import {onFind} from "@elements/init-modules-in-scope";
import {closest, findIn, on} from "@elements/dom-utils";

export function init() {
    onFind('.js-form-tracking', function (form) {

        let trackingEvent = form.dataset.trackingEvent;
        console.log(trackingEvent);

        on('submit', function (){
            window.dataLayer = window['dataLayer'] || [];
            let dataLayerData = {
                'event': trackingEvent,
            };
            window.dataLayer.push(dataLayerData);
        }, form)

    });
}