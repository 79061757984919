import {find, findIn } from "@elements/dom-utils"
import Modal from 'bootstrap/js/dist/modal';

import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass} from "@elements/dom-utils";


export function init () {
    onFind('#infoModal', function () {
        let infoArea = find('#infoModal');
        if(infoArea){
            let infoModal = new Modal(infoArea);

            infoArea.addEventListener('hide.bs.modal', function () {
                infoModal.hide();
                sessionStorage.setItem('infoModal','closed');
            });

            let modal = find('.js-info-modal');
            if (modal) {
                let modalLink = findIn('.js-info-modal__link', modal);
                modalLink.addEventListener('click', function(){
                    infoModal.hide();
                    sessionStorage.setItem('infoModal','closed');
                })

                let count = sessionStorage.getItem('loadCount') || 0;
                sessionStorage.setItem('loadCount', parseInt(count) + 1);

                if(modal.dataset.showcount && sessionStorage.getItem('infoModal') !== 'closed') {

                    let sec = modal.dataset.showcount;

                    let t = setTimeout(function(){
                        infoModal.show();
                        clearTimeout(t);
                    }, sec);

                    sessionStorage.setItem('infoModal','closed');


                } else if(sessionStorage.getItem('loadCount') > 1 && sessionStorage.getItem('infoModal') !== 'closed') {
                    infoModal.show();
                }

                if (sessionStorage.getItem('infoModal') === 'closed') {
                    infoModal.hide();
                    addClass('is-hidden', modal);
                }
            }
        }
    });


}