"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope,onFind} from "@elements/init-modules-in-scope";
import {findIn,closest,removeClass, findAllIn, findAll, on, off, getAttribute, addClass, hasClass, setAttribute} from '@elements/dom-utils';
import {renderTemplate} from "./render-template";

const selectors = {
    base: '.js-nav',
    toggleOverlay: '.js-nav__toggle-overlay',
    showOverlay: '.js-nav__show-overlay',
    collapse: '.js-nav__collapse',
    overlay: '.js-nav__overlay',
    list: '.js-nav__list',
    listItem: '.js-nav__list-item',
    listToggle: '.js-nav__list-toggle'
};



const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {
    onFind(selectors.base, function (baseElement) {
        let toggleOverlays = findAll(selectors.toggleOverlay);


        toggleOverlays.forEach(function(toggleOverlay) {

            on('click', function(evt) {

                let allOverlays = findAll('.js-nav__overlay', baseElement);
                let targetId = toggleOverlay.getAttribute('data-nav-overlay-target');
                let overlayItem = findIn(targetId, baseElement);
                let renderTemplateItem = findIn('.js-render-template', baseElement);

                if(renderTemplateItem) {
                    renderTemplate(renderTemplateItem);
                }

                //if another overlay is currently opened hide it
                allOverlays.map(function (overlay){
                    if(hasClass('show-overlay', overlay)){
                        let targetId = overlay.getAttribute('id');
                        let currentItem = findIn('[data-nav-overlay-target="#' + targetId + '"]', baseElement);
                        if(overlay !== overlayItem){
                            hideItem(currentItem);
                            hideOverlay(overlay);
                        }
                    }
                });

                let searchInput = findIn('.js-nav__overlay-search-input', overlayItem);

                if(hasClass('show-overlay', overlayItem)) {
                    hideItem(toggleOverlay);
                    hideOverlay(overlayItem);
                    removeClass('body--freezed', document.body);
                } else {
                    showItem(toggleOverlay);
                    showOverlay(overlayItem);
                    if (searchInput) {focusInput(searchInput);}

                    addClass('body--freezed', document.body);
                }

                function focusInput(input) {
                    // iOS needs focusing directly in the click event
                    input.focus();

                    let timeout = setTimeout(function () {
                        /* timeout is needed because some browsers don't allow focusing a element
                        if it is not visible */
                        input.focus();
                        clearTimeout(timeout);
                    }, 500);
                }

                function bodyClickHandler(evt) {
                    if (!closest('.main-navbar', evt.target)) {
                        evt.preventDefault();
                        hideItem(toggleOverlay);
                        hideOverlay(overlayItem);
                        removeClass('body--freezed', document.body);
                    }
                }

                function showOverlay(overlay) {
                    addClass('show-overlay', overlay);
                    setAttribute('aria-expanded', true, overlay);
                    if (!isMobile()) {
                        setTimeout(function() {
                            addBodyClickHandler(bodyClickHandler);
                        }, 300);
                    }
                }
                function hideOverlay(overlay) {
                    removeClass('show-overlay', overlay);
                    setAttribute('aria-expanded', false, overlay);
                    if (!isMobile()) {
                        removeBodyClickHandler(bodyClickHandler);
                    }
                }

            }, toggleOverlay);
        });

        onFind(selectors.list, function (list) {
            let listItems = findAllIn(selectors.listItem, list);
            let listToggle = findAllIn(selectors.listToggle, list);

            listToggle.forEach(function(item) {
                on('click', function (evt) {
                    let element = evt.target;
                    evt.preventDefault();

                    let parentItem = closest(selectors.listItem, element);

                    if (hasClass('is-open', parentItem)) {
                        hideItem(parentItem);
                    } else {
                        hideItems(listItems);
                        showItem(parentItem);
                    }
                }, item);
            });
        });
    });
}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}
function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}
function hideItem(item) {
    removeClass('is-open', item);
    removeClass('is-open--only-desktop', item);
    setAttribute('aria-expanded', false, item);
}
function hideItems(items) {
    items.forEach(function(item) {
        removeClass('is-open', item);
        removeClass('is-open--only-desktop', item);
        setAttribute('aria-expanded', false, item);
    });

}
function showItem(item) {
    addClass('is-open', item);
    setAttribute('aria-expanded', true, item);
}
