"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {translate} from '@elements/translations';
import Pristine from "../libs/pristine/pristine.js";
import {onEnterViewPort} from "@elements/viewport-utils";
import {matches} from "@elements/dom-utils";
import {on, findAllIn, hasClass, closest, addClass, removeClass} from "@elements/dom-utils";

const defaultOptions = {
    classTo: 'form-group',
    classToGroup: 'pristine-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

let pristineForms = [];

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {

    onEnterViewPort(form, function () {
        const pristine = new Pristine(form, options);

        if(form.getAttribute('data-form-validation-with-disabled-submit')) {
            toggleSubmitButtonClass(form, pristine);

            on('change', function() {
                toggleSubmitButtonClass(form, pristine);
            }, form);
        }

        form.addEventListener('submit', function (e) {
            // check if the form is valid
            if (!pristine.validate()){
                e.preventDefault();
            }
        });
    });

}

export function isValidationForm (form) {
    return matches('.js-form-validation', form);
}

export function isValid(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();
    }else{
        return 'Using parsley without form';
    }
}


function toggleSubmitButtonClass(form, pristine) {
    let formElements = form.querySelectorAll('input:not([type="hidden"]), select');

    let wrapper = closest('.js-form-validation__wrapper', form);
    let submitButtons = findAllIn('[type="submit"]', wrapper);
    if(!pristine.validate(formElements, true)) {
        submitButtons.map(function(btn) {
            if(!hasClass('js-form-validation__submit-ignore-validation-style', btn)) {
                addClass('form-validation-not-valid', btn) ;
            }
        });
    } else {
        submitButtons.map(function(btn) {
            removeClass('form-validation-not-valid', btn) ;
        });
    }
}